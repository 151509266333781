<template>
  <v-card
    :class="`
      todo-card d-flex flex-column
    `"
    max-width="100%"
    width="500px"
    max-height="145px"
  >
    <v-card-text
      ref="todoContentRef"
      style="flex: 1;"
      :class="`
        todo-content
        d-flex
        flex-column
        py-1
        px-2
        ${cardStateClasses}
      `"
    >
      <div
        class="flex-grow-1"
      >
        <div
          class="d-flex justify-space-between"
        >
          <div class="text-uppercase font-weight-bold caption d-flex flex-wrap align-center">
            <span>{{ todo.address }}</span>
            <span class="pe-1">&#x2022; {{ estimatedDuration }}</span>
            <template v-if="todo.requiresSupervision">
              <div class="d-flex align-center">
                <v-icon x-small class="pe-1" color="warning">warning</v-icon> 
                <span v-translate translate-context="MTodoCardByTeam component content">
                  Pace-setting
                </span>
              </div>
            </template>
          </div>

          <v-tooltip
            v-if="hasCheckupItems"
            bottom
            content-class="pa-0"
          >
            <template v-slot:activator="{ on: tooltip }">
              <v-icon
                color="white"
                class="ml-2 mr-n1"
                v-on="tooltip"
              >
                info
              </v-icon>
            </template>

            <v-card
              dark
              max-width="300px"
            >
              <v-card-text>
                <span
                  v-translate
                  translate-context="MTodosFeedCard component content"
                >
                  This task has a checkup list. Please check up all items
                  in the list first and then mark the task as done.
                  You can open the list by clicking
                </span>
                <span
                  v-translate
                  translate-context="MTodosFeedCard component content"
                  class="m-state-completed px-1 caption"
                >
                  "Check & Done"
                </span>
              </v-card-text>
            </v-card>
          </v-tooltip>
        </div>
        <div class="text-uppercase font-weight-bold subtitle-2 py-0 single-line">
          {{ todo.taskName }}
        </div>
      </div>

      <div
        v-if="todo.comment"
        class="flex-grow-1"
      >
        <p
          class="font-italic mb-0 caption"
        >
          <v-icon
            small
            dark
            left
          >
            announcement
          </v-icon> {{ todo.comment }}
        </p>
      </div>

      <div
        style="line-height: 15px;"
      >
        <div
          class="d-flex justify-space-between"
        >
          <p
            class="caption-xs mb-0"
          >
            {{ todo.teamName }}
          </p>

          <p
            v-translate="{ startsAt, taktNumber: todo.takt }"
            translate-context="MTodosFeedCard component content"
            class="caption-xs mb-0 font-italic"
          >
            %{ startsAt } (Takt %{ taktNumber })
          </p>
        </div>

        <div
          class="d-flex justify-space-between"
        >
          <p
            ref="assigneeRef"
            class="caption-xs mb-0"
          >
            {{ assigneeFullName }}
          </p>

          <p
            ref="todoName"
            class="caption-xs mb-0 font-italic"
          >
            {{ humanReadableState }}
          </p>
        </div>
      </div>
    </v-card-text>

    <v-card-actions
      v-if="showActions"
      class="pa-0"
    >
      <v-row
        no-gutters
        class="mx-2"
      >
        <v-col
          class="d-flex align-center"
        >
          <div
            v-if="interrupted"
          >
            <p
              class="caption-xs mb-0 pl-1"
              :style="{
                lineHeight: '14px',
              }"
            >
              {{ todo.activeInterruptionHumanReadableReason }}

              <br>

              <span
                class="font-italic backgroundAccent--text text--darken-1"
              >
                {{ todo.activeInterruptionComment }}
              </span>
            </p>
          </div>

          <div
            v-else-if="skipped"
          >
            <p
              class="caption-xs mb-0 pl-1"
              :style="{
                lineHeight: '14px',
              }"
            >
              {{ humanReadableState }}

              <br>

              <span
                class="font-italic backgroundAccent--text text--darken-1"
              >
                {{ todo.commentOnSkip }}
              </span>
            </p>
          </div>

          <v-btn
            v-else
            ref="interruptBtnRef"
            :disabled="hiddenInterruptionBtn || $wait.is(loadingId)"
            text
            color="error"
            small
            @click="openInterruptionDialog"
          >
            <span
              v-translate
              translate-context="MTodosFeedCard component interrupt button"
            >
              Interrupt?
            </span>
          </v-btn>
        </v-col>

        <v-col
          class="d-flex align-center justify-center"
        >
          <v-menu
            offset-y
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-btn
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon
                  style="transform: rotate(90deg);"
                >
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-list
              dense
              two-line
            >
              <v-list-item
                @click="openTodoCommentDialog"
              >
                <v-list-item-icon
                  class="mr-4"
                >
                  <v-icon>
                    comment
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-translate
                    translate-context="MTodosFeedCard component content"
                  >
                    Add a comment
                  </v-list-item-title>

                  <v-list-item-subtitle
                    v-translate
                    translate-context="MTodosFeedCard component content"
                  >
                    Task details and observations
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item
                :disabled="!disabled && !enabled"
                :style="{
                  opacity: (!disabled && !enabled) ? 0.5 : 1,
                }"
                @click="openSkipDialog"
              >
                <v-list-item-icon
                  class="mr-4"
                >
                  <v-icon>
                    not_interested
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-translate
                    translate-context="MTodosFeedCard component content"
                  >
                    Skip task
                  </v-list-item-title>

                  <v-list-item-subtitle
                    v-if="disabled || enabled"
                    class="caption"
                  >
                    <span
                      v-translate
                      translate-context="MTodosFeedCard component content"
                    >
                      Set task as skipped
                    </span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-else
                    class="caption"
                  >
                    <!-- Dirty layout required for correct word spacing -->
                    <span
                      v-translate
                      translate-context="MTodosFeedCard component content"
                    >
                      Only works in state
                    </span> <span
                      v-translate
                      translate-context="MTodosFeedCard component content"
                      class="px-1 m-state-enabled"
                    >Can begin</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item
                :disabled="hidePreviousStateButton || $wait.is(loadingId)"
                :style="{
                  opacity: (hidePreviousStateButton || $wait.is(loadingId)) ? 0.5 : 1,
                }"
                @click="setPreviousState"
              >
                <v-list-item-icon
                  class="mr-4"
                >
                  <v-icon>
                    settings_backup_restore
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-translate
                    translate-context="MTodosFeedCard component content"
                  >
                    Previous state
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="caption"
                  >
                    <span
                      v-if="started || completed"
                    >
                      <!-- Dirty layout required for correct word spacing -->
                      <span
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 1 [1/2]"
                      >
                        Set task to state
                      </span> <span
                        v-if="started"
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 1 [2/2]"
                        class="px-1 m-state-enabled"
                      >Can begin</span> <span
                        v-if="completed"
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 1 [2/2]"
                        class="px-1 m-state-started"
                      >Started</span>
                    </span>
                    <span
                      v-if="!started && !completed"
                    >
                      <!-- Dirty layout required for correct word spacing -->
                      <span
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 2 [1/4]"
                      >
                        Only states
                      </span> <span
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 2 [2/4]"
                        class="px-1 m-state-started"
                      >Started</span> <span
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 2 [3/4]"
                      >
                        or
                      </span> <span
                        v-translate
                        translate-context="MTodosFeedCard component content revert state 2 [4/4]"
                        class="px-1 m-state-completed"
                      >Complete</span>
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-col
          class="d-flex align-end justify-center flex-column"
        >
          <v-btn
            ref="nextStateRef"
            :disabled="disableStateChangeButton"
            :loading="$wait.is(loadingId)"
            :hidden="hidden"
            :text="text"
            :color="enabled ? 'info' : 'success'"
            :dark="enabled && !$wait.is(loadingId)"
            small
            :tile="waiting"
            @click="nextState"
          >
            <div
              class="d-flex flex-column"
            >
              <div
                class="d-flex justify-center align-center"
              >
                {{ buttonText }}

                <v-icon
                  v-if="(started || completed) && !interrupted"
                  class="ml-2"
                  small
                >
                  thumb_up
                </v-icon>
              </div>

              <div
                v-if="waiting && todo.dependingTodo"
                class="caption-xxs text-uppercase mt-1 relative waiting-content-container"
              >
                <div
                  class="content"
                >
                  <span
                    v-translate
                    translate-context="MTodosFeedCard component content"
                  >
                    Waiting for
                  </span>
                  {{ todo.dependingTodo.address }} &#x2022; {{ todo.dependingTodo.taskName }}
                </div>
              </div>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      todo: {
        type: Object,
        required: true,
      },
    },

    computed: {
      ...mapGetters({
        showStatsInTodoCard: 'project/settings/showStatsInTodoCard',
        isMobile: 'utils/isMobile',
        userFullName: 'users/userFullName',
      }),

      startsAt() {
        if (this.todo.startsAt) {
          return this.$dayjs(this.todo.startsAt).format('DD.MM.');
        }

        return null;
      },

      // isOwnAssignment() {
      //   return this.assignment.isOwn;
      // },

      state() {
        return this.todo.state;
      },

      disabled() {
        return this.state === 'disabled';
      },

      enabled() {
        return this.state === 'enabled';
      },

      started() {
        return this.state === 'started';
      },

      completed() {
        return this.state === 'completed';
      },

      interrupted() {
        return this.state === 'interrupted';
      },

      waiting() {
        return this.state === 'waiting';
      },

      skipped() {
        return this.state === 'skipped';
      },

      hasCheckupItems() {
        return this.todo.hasCheckupItems;
      },

      cardStateClasses() {
        return `m-state-${this.state}`;
      },

      estimatedDuration() {
        const d = Math.round((this.todo.estimatedDuration / 60) * 10) / 10;

        return `${d} h`;
      },

      loadingId() {
        return `processing todo ${this.todo.id}`;
      },

      hidden() {
        if (this.disabled) {
          return false;
        }

        if (this.enabled) {
          return false;
        }

        if (this.interrupted) {
          return false;
        }

        if (this.$currentUser.isManager) {
          return false;
        }

        // if (this.isOwnAssignment) {
        //   return false;
        // }

        return false;
      },

      hiddenInterruptionBtn() {
        if (this.interrupted) {
          return true;
        }

        if (this.completed) {
          return true;
        }

        if (this.$currentUser.isManager) {
          return false;
        }

        // if (this.isOwnAssignment) {
        //   return false;
        // }

        return false;
      },

      disableStateChangeButton() {
        if (this.$wait.is(this.loadingId)) {
          return this.$wait.is(this.loadingId);
        }

        if (this.waiting) {
          return true;
        }

        if (this.disabled) {
          return false;
        }

        if (this.enabled) {
          return false;
        }

        if (this.completed) {
          return true;
        }

        if (this.$currentUser.isManager) {
          return false;
        }

        // if (!this.isOwnAssignment) {
        //   return true;
        // }

        return false;
      },

      text() {
        if (this.$wait.is(this.loadingId)) {
          return this.$wait.is(this.loadingId);
        }

        if (this.disabled) {
          return false;
        }

        if (this.completed) {
          return true;
        }

        if (this.interrupted || this.skipped) {
          return true;
        }

        if (this.waiting) {
          return true;
        }

        return false;
      },

      assigneeFullName() {
        return this.userFullName(this.todo.currentOwnerId);
      },

      buttonText() {
        if (this.disabled || this.enabled) {
          return this.$pgettext('MTodosFeedCard component button', 'Begin');
        }

        if (this.interrupted) {
          return this.$pgettext('MTodosFeedCard component button', 'Continue?');
        }

        if (this.skipped) {
          return this.$pgettext('MTodosFeedCard component button', 'Open?');
        }

        if (this.waiting) {
          return this.todo.humanReadableState;
        }

        if (this.started && this.hasCheckupItems) {
          return this.$pgettext('MTodosFeedCard component button', 'Check & Done');
        }

        return this.$pgettext('MTodosFeedCard component button', 'Done');
      },

      hidePreviousStateButton() {
        if (this.disabled) {
          return true;
        }

        if (this.enabled) {
          return true;
        }

        if (this.interrupted || this.skipped) {
          return true;
        }

        // if (!this.$currentUser.isManager && !this.isOwnAssignment) {
        //   return true;
        // }

        return false;
      },

      showActions() {
        return true;

        // if (this.disabled || this.enabled) return true;
        // if (this.$currentUser.isManager) return true;
        //
        // return this.isOwnAssignment;
      },

      address() {
        return this.todo.address;
      },

      humanReadableState() {
        return this.todo.humanReadableState;
      },
    },

    methods: {
      ...mapActions({
        startAssignment: 'project/todos/startAssignment',
        cancelAssignment: 'project/todos/cancelAssignment',
        completeAssignment: 'project/todos/completeAssignment',
        continueAssignment: 'project/todos/continueAssignment',
        unskipAssignment: 'project/todos/unskipAssignment',
        resolveAssignment: 'project/todos/resolveAssignment',
        openDialog: 'dialog/openDialog',
      }),

      async start() {
        const {
          id: todoId,
        } = this.todo;

        await this.startAssignment({
          id: todoId,
        });

        /**
         * Assumes that a failed startAssignment() is caused by the earlier incompleted
         * todos. This works most of the times but might be an issue later.
         */

        // TODO: WIP!

        /**
         * Commenting this out. We need to re-write this after the previous refactoring.
         */
        // if (!success) {
        //   this.openDialog({
        //     dialogComponent: 'm-warning-previous-todo-not-completed',
        //     dialogProps: {
        //       todo: this.todo,
        //       onConfirm: async () => {
        //         await this.startAssignment({
        //           id: todoId,
        //           params: {
        //             transition_confirmed: true,
        //           },
        //         });
        //       },
        //     },
        //     config: {
        //       fullscreen: this.isMobile,
        //       scrollable: true,
        //     },
        //   });
        // }
      },

      async cancel() {
        await this.cancelAssignment({
          id: this.todo.id,
        });
      },

      async resume() {
        await this.continueAssignment({
          id: this.todo.id,
        });
      },

      async complete() {
        await this.completeAssignment({
          id: this.todo.id,
        });
      },

      async unskip() {
        await this.unskipAssignment({
          id: this.todo.id,
        });
      },

      async resolve() {
        await this.resolveAssignment({
          id: this.todo.id,
        });
      },

      openInterruptionDialog() {
        this.openDialog({
          dialogComponent: 'm-interrupt-todo-dialog',
          dialogProps: {
            todo: this.todo,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      openSkipDialog() {
        this.openDialog({
          dialogComponent: 'm-skip-todo-dialog',
          dialogProps: {
            todo: this.todo,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      openResolveDialog() {
        this.openDialog({
          dialogComponent: 'm-confirm-base-dialog',
          dialogProps: {
            confirm: this.resolve,
            prompt: this.$pgettext('MTodosFeedCard component open dialog prompt', 'Would you like to continue working?'),
            icon: 'thumb_up',
            iconColor: 'success',
          },
        });
      },

      openCheckupDialog() {
        this.openDialog({
          dialogComponent: 'm-checkup-dialog',
          dialogProps: {
            todo: this.todo,
          },
          config: {
            fullscreen: this.isMobile,
            scrollable: true,
          },
        });
      },

      openUnskipDialog() {
        this.openDialog({
          dialogComponent: 'm-confirm-base-dialog',
          dialogProps: {
            confirm: this.unskip,
            prompt: this.$pgettext('MTodosFeedCard component open dialog prompt', 'Should the task be done?'),
            icon: 'thumb_up',
            iconColor: 'success',
          },
        });
      },

      openTodoCommentDialog() {
        this.openDialog({
          dialogComponent: 'm-todo-comment-dialog',
          dialogProps: {
            todo: this.todo,
          },
          config: {
            fullscreen: this.isMobile,
          },
        });
      },

      async nextState() {
        if (this.interrupted) {
          this.openResolveDialog();

          return;
        }

        if (this.skipped) {
          this.openUnskipDialog();

          return;
        }

        this.$wait.start(this.loadingId);

        if (this.disabled || this.enabled) {
          await this.start();
        } else if (this.started) {
          if (this.hasCheckupItems) {
            this.openCheckupDialog();
          } else {
            await this.complete();
          }
        }

        this.$wait.end(this.loadingId);
      },

      async setPreviousState() {
        const confirmed =  confirm(this.$pgettext('MTodosFeedCard component confirm reverting state', 'Would you like to revert this task to its previous state?')); // eslint-disable-line

        if (!confirmed) return;

        if (this.started) {
          await this.cancel();
        }

        if (this.completed) {
          await this.resume();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .todo-card {
    border-radius: 4px;

    .todo-content {
      overflow-wrap: break-word;
    }
  }

.waiting-content-container {
  position: relative;
  max-width: 112px;
  overflow: hidden;
  height: 10px;
  color: var(--v-primary-lighten2);

  .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
    animation: moveSlideshow 8s linear infinite;
  }
}

@keyframes moveSlideshow {
  0% {
    transform: translateX(0);
  }

  49.999% {
    transform: translateX(-100%);
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  50.001% {
    transform: translateX(100%);
  }

  50.002% {
    opacity: 1;
  }

  60% {
    transform: translateX(0);
  }
}
</style>
